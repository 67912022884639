import _ from "lodash";

export default {
	name: 'form-percent',
	props: ['value', 'label', 'rules', 'required', 'customProps'],

	data() {
		return {
			errors: []
		}
	},
	created() {
		this.allRules = [
			v => (v >= 0 && v <= 1) || "Must be in range (0 ... 100)",
			...this.rules || []
		];

		this.validate(this.result);
	},
	computed: {
		result: {
			get() {
				return this.value ? Math.floor(this.value * 100) : null;
			},
			set(value) {
				this.errors = [];
				if (!value || isNaN(value)) return this.errors.push('Number format incorrect')

				// Division on float will cast result to float
				const result = value / 100.0;
				this.validate(result);

				this.$emit('input', result);
			}
		}
	},
	methods: {
		async validate(value) {
			let isValid = true;

			if (this.allRules && this.allRules.length) {
				await Promise.all(
					_.map(this.allRules, async (validator) => {
						const result = await validator(value);
						if (result !== true) this.errors.push(result)
					})
				);

				isValid = (this.errors.length === 0);
			}

			this.$emit("valid", isValid);
		}
	}
};
